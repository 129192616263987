.form-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.search-cars-container{
    margin-top: 70px;
}
.section-title{
    text-align: left;
    width: 100%;
    margin: 50px 0px 20px 0px;
}
.submit-button{
    height: 38.48px;
    border: none;
    border-radius: 5px;
    width: 100%;
    background: #EEA711;
    font-size: 18px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hr{
    width: 100%;
    background-color: gray;
    height: 1px;
    margin-bottom: 15px;
}
.c-page-details{
    font-size: 20px;
}