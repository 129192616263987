.services-icon{
    width: 90px;
}
.services-slogan{
    font-size: 21px;
    color: rgb(177, 177, 177);
}
.service-image-box{
    height: 85px;
}
.services-on-slicer{
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: "111111";
    position: relative;
}
.information-banner{
    width: 100%;
    height: 720px;
    background-image: url('https://crg.ge/static/images/banner-image.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 50px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-text-box{
    width: 90%;
    max-width: 700px;
    padding: 30px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    .banner-text{
        color: white;
        text-align: center;
    }
}
@media(max-width: 767px){
    .services-icon{
        width: 40px;
    }
    .services-slogan{
        font-size: 15px;
    }
    .service-image-box{
        height: 45px;
    }
    .service{
        width: 33% !important;
    }
    .services-list{
        margin-top: -90px !important;
    }
    .full-width{
        width: 100% !important;
        max-width: 100% !important;
    }
}
@media (max-width: 500px) {
    .services-icon{
        width: 33px;
    }
    .services-slogan{
        font-size: 12px;
        margin-bottom: 0px;
    }
}