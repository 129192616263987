/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

.bm-item:hover {
    color: #ffffff;
}

* {
    box-sizing: border-box;
}

#page-wrap {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 100px;
}

.bm-menu-wrap{
    z-index: 1111111111111 !important;
}


/* Styles for the burger button */
.burger-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    padding: 10px;
  }
  
  /* Styles for the burger menu container */
  .burger-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
  }
  
  /* Styles for the navigation menu */
  .menu-items {
    position: fixed;
    top: 0px; /* Adjust the top position as needed */
    right: 0px;
    background-color: #413b3b;
    border: 1px solid #413b3b;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    height: 100vh;
    z-index: 11111111111111111111;
    overflow: hidden;
    max-height: 100vh;
  }
  
  /* Styles for the open menu */
  .menu-items.open {
    width: 260px; /* Adjust the maximum height as needed */
    transition: 1.5s;
  }
  .sidebar-text{
    font-size: 22px;
  }
  /* Styles for the navigation links */
  .menu-items ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-items ul li {
    margin-bottom: 10px;
  }

  .menu-items ul li a{
    color: white;
  }
  
  .menu-items a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  /* Hover effect for navigation links */
  .menu-items a:hover {
    color: #007bff;
  }