.car-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.car-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.flex-column-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.flex-column-left p {
  margin-bottom: 0px;
  color: black;
  text-decoration: none;
}

.car-item-container {
  text-decoration: none;
  margin-bottom: 40px;
}

.car-info {
  font-size: 18px;
  font-weight: bold;
  color: rgb(71, 71, 71);
}

.car-price-info {
  display: flex;
  align-items: center;
}
.car-price-info p, .car-price-info h2, .car-price-info span {
  margin-bottom: 0px;
  color: #EEA711;
  font-weight: bold;
}
.car-price-info .currency {
  text-align: left;
  margin-left: 8px;
}

.details-button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  color: white;
  background: rgb(61, 61, 61);
}

.about-us-text {
  text-align: left;
  color: rgb(255, 255, 255);
}

.terms-and-cond {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.target-link {
  text-decoration: underline;
  cursor: pointer;
}

.options-name img {
  width: 45px;
  margin-right: 15px;
}

.options-name {
  display: flex;
  align-items: center;
}

.checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-us-banner {
  background-image: url("https://crg.ge/static/images/about-us-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 80px);
  margin-top: -50px;
}

.load-more-button {
  margin-bottom: 50px;
  background-color: #EEA711;
  border: none;
  width: 100%;
  max-width: 230px;
  height: 40px;
  border-radius: 6px;
  color: white;
  font-size: 18px;
}

.car-description-container {
  padding-top: 35px;
}

.car-images-slider {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  border-radius: 8px;
}

.slider-action-buttons {
  border: none;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.actions-buttons {
  color: white;
  font-size: 18px;
}

.car-description-cotnainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.locations-on-cars-page {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.locations-on-cars-page li {
  margin-right: 8px;
}

.car-details-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.car-details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.car-details-list li strong {
  text-align: left;
  width: 140px;
}
.car-details-list li p {
  margin-bottom: 0px;
}

.price-include-box {
  background: #EEA711;
  border-radius: 6px;
  height: 116px;
}

.insurance-conditions-box {
  background: rgba(238, 167, 17, 0.8);
  margin-top: 20px;
  padding: 20px;
  border-radius: 6px;
}
.insurance-conditions-box ul li {
  color: white;
  text-align: left;
  font-size: 15px;
}

.booking-details {
  margin-top: 65px;
}

.start-booking-button {
  width: 100%;
  height: 38px;
  background: #EEA711;
  border: #EEA711 1px solid;
  border-radius: 4px;
  color: white;
  margin-bottom: 20px;
}

.logo-in-details {
  width: 250px;
}

.order-details-title {
  font-size: 26px;
  font-weight: bold;
}

#google-translate {
  top: 0;
}

body {
  top: 40px !important;
}

.goog-te-combo {
  height: 30px;
  width: 100%;
}

.bm-burger-button {
  display: none;
}

#goog-gt-tt {
  display: none !important;
}

.order-details-date {
  font-size: 18px;
  font-weight: 600;
}

.personal-details-containersi {
  margin-top: 10px;
  border: rgb(206, 206, 206) 1px solid;
  padding: 8px;
  border-radius: 6px;
}

.order-details-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .car-images-slider {
    height: 260px;
  }
  h4 {
    font-size: 19px !important;
  }
  h5 {
    font-size: 17px !important;
  }
  h6 {
    font-size: 15px !important;
  }
  strong {
    font-size: 13px;
  }
}/*# sourceMappingURL=CarsElement.css.map */