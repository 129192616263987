.form-group{
    margin-bottom: 20px;
}
.profile-image-on-modal{
    width: 90%;
    margin-bottom: 20px;
}
.date-filter-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
    flex-wrap: wrap;
}
.insurance-alert-list{
    list-style-type: none;
    li{
        display: flex;
        align-items: center;
        span{
            margin-right: 8px;
        }
    }
}
.additional-filters{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
}