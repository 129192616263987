.section-title {
  font-size: 24px;
  text-align: left;
  margin: 40px auto 20px auto;
}

.data-table-header {
  margin: 15px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.data-table-header .data-table-search-box {
  max-width: 350px;
  display: flex;
}
.data-table-header .data-table-search-box input {
  margin-right: 10px;
}
.data-table-header .database-header-actions {
  display: flex;
  align-items: center;
}
.data-table-header .data-table-action-buttons {
  margin-left: 10px;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.orange-button {
  border: none;
  color: white;
  border-radius: 4px;
  background-color: #EEA711;
  height: 38px;
}

.table {
  margin-bottom: 0 !important;
}

.data-table-container {
  border-radius: 10px;
  overflow: hidden;
  margin: 50px auto 0 auto;
  border: 1px solid #1e1f21;
}
.data-table-container thead {
  background: #1e1f21 !important;
}
.data-table-container thead tr {
  background: #1e1f21 !important;
}
.data-table-container thead th {
  background: #1e1f21 !important;
  color: white;
  border-right: 1px solid white;
  font-size: 16px;
  padding: 5px 0;
}
.data-table-container thead th:last-child {
  border-right: none;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-button {
  color: #0a56f7;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
  margin-left: 8px;
}

.modal-section-title {
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
}

.upload-images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-file-button {
  padding: 7px 15px;
  border-radius: 10px;
  background: #EEA711;
  color: white;
  border: none;
  cursor: pointer;
}

.pagination-container span {
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}

.pagination-container .active-page-number {
  color: #1ED8B9;
}

.actions-dropdown {
  cursor: pointer;
  font-size: 20px;
}

.actions-list {
  list-style-type: none;
  font-size: 15px;
}
.actions-list li {
  padding: 5px 20px;
  cursor: pointer;
}
.actions-list li:hover {
  background: #0a56f7;
  color: white;
}

.actions-dropdown-box {
  position: absolute;
  background: white;
  padding: 8px 0;
  box-shadow: 0 0 8px 0 gray;
  border-radius: 8px;
}

.actions-list {
  padding: 0;
  margin: 0;
}

.no-padding {
  padding: 0 !important;
}

.data-table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto 0px auto;
}

.form-dates {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.select-filters {
  display: flex;
  align-items: center;
}

@media (max-width: 676px) {
  .data-table-container {
    margin-top: 20px;
  }
  .database-header-actions {
    margin-top: 20px;
  }
  .data-table-header {
    justify-content: flex-end;
  }
  .actions-dropdown-box {
    margin-left: -150px;
  }
  .form-dates {
    margin-top: 20px;
  }
  .form-dates span {
    margin: 10px auto 0 auto;
  }
}
@media (max-width: 570px) {
  .actions-dropdown-box {
    margin-left: -290px;
  }
}/*# sourceMappingURL=DataTable.css.map */