.admin-auth-container {
    width: 100%;
    min-height: calc(100vh - 240px);
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-box {
        width: 90%;
        max-width: 400px;
        padding: 30px;
        box-shadow: 0px 0px 20px 5px rgb(211, 211, 211);
        border-radius: 10px;

        input {
            margin-bottom: 20px;
        }
    }
}

.fade.modal-backdrop.show {
    z-index: 1111111111;
}

.fade.modal.show {
    z-index: 11111111111;
}