.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  background: gray;
  color: white;
  margin-bottom: 8px;
  margin-right: 5px;
  cursor: pointer;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.footer-modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkbox-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkboxes {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 15px;
}
.checkboxes label {
  margin-left: 5px;
}

.multiple-selects {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricing-list {
  list-style-type: none;
  margin: 0;
  padding: 0px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #29D522;
  width: 100%;
}
.pricing-list li {
  color: white;
  width: 25%;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid white;
}
.pricing-list li:last-child {
  border-right: none;
}
.pricing-list li p {
  margin-top: 8px;
  margin-bottom: 0px;
}

.cars-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.pricing-container {
  margin-top: 45px;
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=addCarsModal.css.map */