.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.images-list {
  width: 100%;
  overflow: hidden;
}
.images-list .slider-container {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.images-list .slider-container img {
  width: 100vw;
}

.action-buttons {
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: 111;
  background: rgb(221, 221, 221);
  border: none;
  border-radius: 50%;
  opacity: 0.5;
}

.banner-slogan {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 111;
  padding: 15px 0px;
  background: rgba(212, 212, 212, 0.7);
}

.slider-item-image {
  transition: 0.5s;
}

.slider-item {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.banner-slogan-container {
  width: 100vw;
  overflow: hidden;
  position: absolute;
  z-index: 111;
  height: 69px;
}/*# sourceMappingURL=BannerSlider.css.map */