.container-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-container {
  background: #EEA711;
  position: fixed;
  z-index: 1111111;
  width: 100%;
}

.navbar-container {
  height: 70px;
  display: flex;
  align-items: center;
}

.navbar-items {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100%;
}
.navbar-items li {
  padding: 0px 10px;
  height: 100%;
  display: flex;
  align-items: center;
}
.navbar-items li a {
  text-decoration: none;
  color: white;
  font-size: 18px;
}

footer a {
  color: white !important;
}

.log-out-button {
  padding: 4px 20px;
}

.burger-button {
  z-index: 11111111111;
  background: none;
  border: none;
  font-size: 30px;
}

.slide-image {
  width: 100%;
}

.footer-container {
  width: 100%;
  height: 160px;
  background: #EEA711;
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 150px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-office-address {
  text-align: left;
}
.footer-office-address p {
  margin-bottom: 0px;
  color: white;
}

.footer-contact {
  text-align: left;
}
.footer-contact p {
  margin-bottom: 0px;
  color: white;
}
.footer-contact a {
  color: rgb(187, 187, 187);
  text-decoration: none;
}

.driver-guide-description {
  width: 100%;
  max-width: 600px !important;
}

.driver-guide-banner {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("https://crg.ge/static/images/driver-guide.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.driver-guide-description {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 30px;
  border-radius: 12px;
}

.terms-and-conditions {
  text-align: left;
  margin-bottom: 60px;
}

.burger-button {
  display: none;
}

.contact-page-container {
  width: 100%;
  min-height: calc(100vh - 240px);
}

@media (max-width: 992px) {
  .burger-button {
    display: block;
  }
  .navbar-container {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer-container {
    height: 300px;
  }
  .contact-page-map {
    margin: 40px 0;
    height: 250px !important;
  }
}/*# sourceMappingURL=Header.css.map */